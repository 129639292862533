// 公司理念
<template>
  <div class="company-concept">
    <div class="concept-content justify-around" v-for="(list,index) in gslns" :key="'l'+index">
      <h2 class="concept-title" :style="{color:list.color}">{{list.name}}</h2>
      <el-row class="concept-list">
        <el-col class="concept-item" v-for="item in list.value" :key="item.id" :span="list.length==2?24:12">
          <p class="concept-item-title">
            <i :style="{backgroundColor:list.color}"></i>
            {{item.name}}
          </p>
          <p class="concept-item-info">{{item.describe}} </p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getCore } from '@/api'
export default {
  name: 'Concept',
  data () {
    return {
      gslns: [],
      colors: ['#5A7EB4', '#B78160']
    };
  },
  computed: {

  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      let { data } = await getCore('gsln')
      for (let i = 0; i < data.length; i++) {
        data[i]['color'] = this.colors[i];
        this.gslns.push(data[i])
      }
    }
  },
};
</script>

<style scoped lang="less">
.concept-content {
  background-color: #fff;
  margin-bottom: 20px;
  padding: 22px 78px;
  .concept-title {
    width: 20px;
    font-size: 28px;
  }
  .concept-list {
    flex: 1;
    margin-left: 118px;

    .concept-item {
      padding: 28px 0;
      p {
        color: #373737;
      }
      .concept-item-title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 14px;
        position: relative;
        i {
          content: "";
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #5a7eb4;
          display: inline-block;
          position: absolute;
          left: -22px;
          top: 4px;
        }
      }
      .concept-item-info {
        width: 90%;
      }
    }
  }
}
</style>
